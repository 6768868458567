import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Header from './Header';
import Greetings from './Greetings'
import { injectIntl } from "react-intl";

class Welcome extends Component {
  render() {
    const {intl} = this.props
    const query = new URLSearchParams(window.location.search);
    const show_logo = query.get('show_logo')
    return (
      <Fragment>
        <div className="container animated fadeIn">
          <div className="row">
            {!this.props.touchscreen_mode || show_logo ?
              <Header
                customer_icon={this.props.site_info.list_image_url}
                site_info={this.props.site_info.name}
                text={intl.formatMessage({id: "issues.browser_header"})}
              /> : null
            }
            {this.props.site_info.show_greetings && !this.props.ui.confirm_greetings ?
              <Greetings /> : null
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

// Map`s the state to props, to get access to specific data from the store.
function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    ui: state.ui
  };
}

// Export and connection to the store.
export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(Welcome)
);
