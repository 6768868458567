import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import * as actions from '../../actions';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { Default } from './elements/Button'
import {scrollToTarget} from "./elements/ScrollToTarget";
import {Modal, Popover} from "react-bootstrap";
import { injectIntl } from "react-intl";

class ServiceGroups extends Component {
  constructor() {
    super();

    this.state = {
      show_modal: false,
      show_modal_description: false,
      service_group_name: null,
      description: null,
      width: 0,
      height: 0,
      active_tooltip: null
    }
  }
  renderTooltip(s) {
    return (
      <Popover id="popover-basic" title={s.name}>
        <div dangerouslySetInnerHTML={this.createMarkup(s.description)} />
      </Popover>
    )
  }
  createMarkup(markup) {
    return { __html: markup };
  }
  handleClose() {
    this.setState({ show_modal: false });
  }
  handleCloseDescription() {
    this.setState({ show_modal_description: false, description: null });
  }
  showDescription(name, text) {
    this.setState({ show_modal_description: true, service_name: name, description: text })
  }
  handleClick(service_group, e) {
    e.preventDefault();
    this.props.resetAppointmentTimes();
    this.props.selectedService("hidden");
    this.props.selectedServiceData("hidden");
    this.props.selectedServiceName([]);
    this.props.selectedServiceGroup(service_group.id);
    this.props.selectedServiceGroupData(service_group);
    this.props.setNoIssues(false);
    this.props.handleReset(false);
    this.props.resetSubtaskGroup();
    this.props.resetSubtasks();
    this.props.resetSubtaskPersons();
    this.props.setServiceEdit();
    scrollToTarget("scroll_target_services")
  }
  renderServiceGroups() {
    const Button = styled.button`
      background: ${this.props.site_info.button_background};
      color: ${this.props.site_info.button_text};
      width: 100%;
      text-align: center;
      font-size: 1.1em;
      padding: 12px;
      margin-bottom: 15px;
      border: 1px solid #CBCBCBFF;
      border-radius: 3px;
      &:hover {
        background-color: ${this.props.site_info.button_hover} !important;
        color: ${this.props.site_info.button_text_hover} !important;
        transition: 500ms;
      }
    `;
    return this.props.site_info.service_groups.map(
      service_group => {
        if(!service_group.group_visible_only_for_desks && !service_group.group_not_publicy_listed){
          return (
              <div className="col-sm-6 mobile-paddings" key={service_group.id} style={{display: 'flex'}}>
                {!this.props.site_info.alternate_buttons ? (
                    <Button
                        className={
                          this.props.site_info.drop_shadows ? 'drop-shadow' : null
                        }
                        onClick={e => !service_group.info_only_no_modal && this.handleClick(service_group, e)}
                        style={{
                          backgroundColor:
                              service_group.info_only_no_modal ? this.props.site_info.info_only_background :
                              this.props.selected_service_group === service_group.id
                                  ? this.props.site_info.button_active
                                  : this.props.site_info.button_background,
                          color:
                              this.props.selected_service_group === service_group.id
                                  ? this.props.site_info.button_text_active
                                  : this.props.site_info.button_text,
                          width: '100%',
                          pointerEvents: service_group.info_only_no_modal ? 'none' : 'auto'
                        }}>
                      <span><div dangerouslySetInnerHTML={this.createMarkup(service_group.name)} /></span>
                    </Button>
                ) : (
                    <div style={{ marginBottom: '15px', width: '100%'  }} onClick={e => !service_group.info_only_no_modal && this.handleClick(service_group, e)}>
                      <Default site_info={this.props.site_info} item={service_group} selected_item={this.props.selected_service_group} icon_type="issues_group"/>
                    </div>
                )}
                {service_group.description && service_group.description.length ?
                    <Fragment>

                      <Button
                          onClick={() => this.showDescription(service_group.name, service_group.description)}
                          className={
                            this.props.site_info.drop_shadows
                                ? 'issue_help drop-shadow-less'
                                : 'issue_help'
                          }
                          style={{
                            fontSize: '1.6rem',
                            width: this.props.site_info.border_on_items ? '55px' : '56px',
                            padding: '0px',
                            marginLeft: '10px',
                            marginTop: this.props.site_info.border_on_items ? '1px' : 0,
                            backgroundColor: this.state.active_tooltip === service_group.id ? this.props.site_info.service_tooltip_color : this.props.site_info
                                .service_tooltip_background_color,
                            color: this.state.active_tooltip === service_group.id ? this.props.site_info
                                .service_tooltip_background_color : this.props.site_info.service_tooltip_color,
                            border: this.props.site_info.border_on_items ? '1px solid black' : '1px solid #CBCBCBFF',
                            borderRadius: 0,
                            outline: 'none'
                          }}>
                        <div dangerouslySetInnerHTML={this.createMarkup(this.props.site_info.service_tooltip_icon)} />
                      </Button>
                    </Fragment> : null
                }
              </div>
          );
        }
        if(service_group.group_visible_only_for_desks && this.props.isUser && !this.props.touchscreen_mode){
          return (
              <div className="col-sm-6 mobile-paddings" key={service_group.id} style={{display: 'flex'}}>
                {!this.props.site_info.alternate_buttons ? (
                    <Button
                        className={
                          this.props.site_info.drop_shadows ? 'drop-shadow' : null
                        }
                        onClick={e => !service_group.info_only_no_modal && this.handleClick(service_group, e)}
                        style={{
                          backgroundColor:
                              service_group.info_only_no_modal ? this.props.site_info.info_only_background :
                              this.props.selected_service_group === service_group.id
                                  ? this.props.site_info.button_active
                                  : this.props.site_info.button_background,
                          color:
                              this.props.selected_service_group === service_group.id
                                  ? this.props.site_info.button_text_active
                                  : this.props.site_info.button_text,
                          width: '100%',
                          pointerEvents: service_group.info_only_no_modal ? 'none' : 'auto'
                        }}>
                      <span>{service_group.name}</span>
                    </Button>
                ) : (
                    <div style={{ marginBottom: '15px', width: '100%'  }} onClick={e => !service_group.info_only_no_modal && this.handleClick(service_group, e)}>
                      <Default site_info={this.props.site_info} item={service_group} selected_item={this.props.selected_service_group} icon_type="issues_group"/>
                    </div>
                )}
                {service_group.description && service_group.description.length ?
                    <Fragment>

                      <Button
                          onClick={() => this.showDescription(service_group.name, service_group.description)}
                          className={
                            this.props.site_info.drop_shadows
                                ? 'issue_help drop-shadow-less'
                                : 'issue_help'
                          }
                          style={{
                            fontSize: '1.6rem',
                            width: this.props.site_info.border_on_items ? '55px' : '56px',
                            padding: '0px',
                            marginLeft: '10px',
                            marginTop: this.props.site_info.border_on_items ? '1px' : 0,
                            backgroundColor: this.state.active_tooltip === service_group.id ? this.props.site_info.service_tooltip_color : this.props.site_info
                                .service_tooltip_background_color,
                            color: this.state.active_tooltip === service_group.id ? this.props.site_info
                                .service_tooltip_background_color : this.props.site_info.service_tooltip_color,
                            border: this.props.site_info.border_on_items ? '1px solid black' : '1px solid #CBCBCBFF',
                            borderRadius: 0,
                            outline: 'none'
                          }}>
                        <div dangerouslySetInnerHTML={this.createMarkup(this.props.site_info.service_tooltip_icon)} />
                      </Button>
                    </Fragment> : null
                }
              </div>
          );
        }
      }
    );
  }

  render() {
    const service_group_header = this.props.site_info.service_group_header_html || this.props.site_info.service_group_header;
    return (
      <Fragment>
        <Modal
          style={{paddingLeft: 0}}
          show={this.state.show_modal}
          onHide={this.handleClose.bind(this)}
          bsSize="large"
          aria-labelledby="contained-modal-title-lg">
          <Modal.Header style={{background: this.props.site_info.heading_background, color: this.props.site_info.heading_text}}>
            <Modal.Title id="contained-modal-title-lg">
              <div dangerouslySetInnerHTML={this.createMarkup(this.props.selected_service_group.name)} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={this.createMarkup(this.props.selected_service_group.description)} />
          </Modal.Body>
        </Modal>
        <Modal
          style={{paddingLeft: 0}}
          show={this.state.show_modal_description}
          onHide={this.handleCloseDescription.bind(this)}
          bsSize="large"
          aria-labelledby="contained-modal-title-lg">
          <Modal.Header style={{background: this.props.site_info.heading_background, color: this.props.site_info.heading_text}} closeButton>
            <Modal.Title id="contained-modal-title-lg">
              <div dangerouslySetInnerHTML={this.createMarkup(this.state.service_name)}/>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={this.createMarkup(this.state.description)} />
          </Modal.Body>
        </Modal>
        <Helmet>
          <style>{`
            .issues_header_left, .slot_picker_header_left, .data_forms_header_left{
              background-color: ${this.props.site_info.heading_background};
              color: ${this.props.site_info.heading_text};
              font-size: 1.1em;
              font-weight: 600;
              padding: 8px;
              border: 1px solid #CBCBCBFF;
              border-bottom: none;
              border-radius: 5px 5px 0px 0px;
            }
          `}</style>
        </Helmet>
        {this.props.site_info &&
        this.props.site_info.service_groups.length ? (
          <div
            className={`container animated fadeIn ${
              this.props.site_info.drop_shadows ? 'drop-shadow' : null
            }`}>
            <div className="row">
              <div className="col-md-12 issues_header_left no_tab">
                <span className="no_tab" style={this.props.site_info.service_group_header_html ? {fontWeight: "initial"} : {fontWeight: 600}} dangerouslySetInnerHTML={this.createMarkup(service_group_header)}></span>
              </div>
            </div>
            <div
              className="row inline-shadow"
              style={{
                backgroundColor: this.props.site_info.content_background,
                color: this.props.site_info.content_text
              }}>
              <div style={{ marginTop: '15px' }} />
              {this.props.site_info.service_groups &&
              this.props.site_info.service_groups.length ? (
                <div>
                  {this.props.site_info.service_groups
                    ? this.renderServiceGroups()
                    : null}
                </div>
              ) : (
                <div className="col-md-6">
                  <ScaleLoader
                    sizeUnit={'px'}
                    size={300}
                    color={this.props.site_info.loading_indicator}
                    loading={true}
                  />
                </div>
              )}
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

// Map`s the state to props, to get access to specific data from the store.
function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    selected_service_group: state.user_selected_service_group
  };
}

// Export and connection to the store.
export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(ServiceGroups)
);
